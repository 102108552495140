import React from "react";
import HowTo2 from "../components/HowTo2";
import SevenDaysBanner from "../components/SevenDaysBanner";
import DefaultHero from "../components/DefaultHero";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import Header from "../components/Header";
import QueryString from "../helper/QueryString";
import Alarmanlagen from "../components/Alarmanlagen";
import Footer from "../components/Footer";
import OurTeam from "../components/OurTeam";
import { graphql } from "gatsby";
import IntrusionAtlas from "../components/IntrusionAtlas";
import StringBuilder from "../helper/StringBuilder";
import OurPartner from "../components/OurPartner";
import NewHome_We_Secure from "../components/NewHome_We_Secure";
import NewHome2 from "../components/NewHome2";
import NewHome3 from "../components/NewHome3";
import FloorPlan from "../components/FloorPlan";
import { useEffect } from "react";
export const HeroElement = ({ city, stringBuilder }) => {
    let path = "";
    if (typeof window != "undefined") {
        path = window.location.pathname;
        path = path.replace(/\//g, "");
    }
    const colourChangeArray = [
        "hamburg",
        "berlin",
        "muenchen",
        "koeln",
        "duesseldorf",
        "oldenburg",
        "bochum",
        "duisburg",
        "krefeld",
        "moenchengladbach",
        "luebeck",
        "potsdam",
        "bonn",
        "frankfurt",
        "rheinland",
        "rhein-main",
        "schliessanlagen",
    ];
    let textColor = "white";
    console.log("path in city is :", path);
    if (colourChangeArray.includes(path)) {
        textColor = "#193351";
    }
    let windowWidth = 541;
    if (typeof window != `undefined`) {
        windowWidth = window.innerWidth;
    }
    if (windowWidth <= 768) {
        textColor = "#193351";
    }
    return (
        <div className="homepage display-max-500">
            <h1 className="top" style={{ color: textColor }}>
                Sicherheitstechnik von der Nr. 1 {stringBuilder.getInStadt()}
            </h1>
            <span
                className="middle"
                style={{ color: textColor, marginTop: "-20px" }}
            >
                Vertrauensvoll. Kompetent. Sicher.
            </span>
            {/* <PriceCalculationButton id="btn_bighero_preisberechnen" /> */}
        </div>
    );
};
// export const HeroElement = ({ city, stringBuilder }) => {
//     return (
//         <div className="city">
//             <h1 className="top">
//                 Sicherheitstechnik von der Nr. 1 {stringBuilder.getInStadt()}
//             </h1>
//             <h2 className="middle">Vertrauensvoll. Kompetent. Sicher.</h2>
//             <PriceCalculationButton city={city.slug} />
//         </div>
//     );
// };

export default function City({ data, pageContext }) {
    const { city, slug } = pageContext;
    let stringBuilder = new StringBuilder(city);
    const contextData = {
        title: city.title,
        image: "/img/hero/" + city.slug + "/" + city.image,
        image768: "/img/hero/" + city.slug + "/" + city.image768,
        image1024: "/img/hero/" + city.slug + "/" + city.image1024,
        image1216: "/img/hero/" + city.slug + "/" + city.image1216,
        image1408: "/img/hero/" + city.slug + "/" + city.image1408,
        showPriceCalculator: true,
        showHeroMobile: false,
        showBottom: true,
        showElement: <HeroElement city={city} stringBuilder={stringBuilder} />,
        city: city,
        noIndex: false,
        backgroundColor: "whiteTextHero",
    };
    console.log(
        "city.slugcity.slugcity.slugcity.slugcity.slugcity.slugcity.slug",
        city.slug
    );
    let query = new QueryString();
    query.setCity(city);

    return (
        <div>
            <Header
                page={contextData.city}
                siteMetadata={data.site.siteMetadata}
                contextData={contextData}
            />
            <DefaultHero context={contextData} />
            {/* <HowTo2 city={city}/>
            <OurPartner/>
            <FloorPlan city={city}/>
            <FaceToFace city={city}/>
            <Testimonials city={city}/>
            <OurTeam city={city} />
            <Alarmanlagen city={city} />
            {/* { city.content &&
                <section className="city-page">
                    <div className="container layout">
                        <div className="content" dangerouslySetInnerHTML={{ __html: city.content }}></div>
                    </div>
                </section>
            } 
            <IntrusionAtlas city={city} />
            <SevenDaysBanner city={city}/> */}

            <NewHome_We_Secure />
            <NewHome3 />

            <HowTo2
            // showPriceCard={
            //     slug && slug.indexOf("/alarmanlagen") ? true : false
            // }
            />
            <OurPartner />
            <FloorPlan />
            <NewHome2 />

            {/* <FaceToFace />
                        <Testimonials />
                        <OurTeam />
                        <Alarmanlagen /> */}
            <IntrusionAtlas context={contextData} />
            <SevenDaysBanner />
            <Footer />
        </div>
    );
}

export const query = graphql`
    query CityQuery {
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
            }
        }
    }
`;
